import Vue from 'vue'
import App from './App.vue'
import router from './router' 
import 'animate.css';
import VueStripeMenu from 'vue-stripe-menu';
// import store from './store/store';
// import draggable from 'vuedraggable';
Vue.use(VueStripeMenu)
// Vue.use(draggable)
 
// Import styles
import 'vue-stripe-menu/dist/vue-stripe-menu.css'

Vue.config.productionTip = false


new Vue({
  // store,
  router,
  render: h => h(App),
}).$mount('#app')
