import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: '',
    redirect: window.location.host.indexOf('nexmem.com') >= 0 ? 'tt' : 'home',
    component: () => import('@/components/main'),
    meta: {
      hideInMenu: true,
    },
    children: [
      {
        path: '/home',
        // name: 'home',
        component: () => import('@/views/home'),
        children:[
          // {
          //   path:'',
          //   redirect:'separate',
          // },
          {
            path:'separate',
            name:'separate',
            component:()=>import('@/views/homeChildren/separate')
          },
          {
            path:'enterprise',
            name:'enterprise',
            component:()=>import('@/views/homeChildren/enterprise')
          },
          {
            path:'education',
            name:'education',
            component:()=>import('@/views/homeChildren/education')
          },
          {
            path:'purchase',
            name:'purchase',
            component:()=>import('@/views/homeChildren/purchase')
          }
        ]
      },
      // {
      //   path: '/theme',
      //   name: 'theme',
      //   component: () => import('@/views/theme')
      // },
      // {
      //   path: '/hall',
      //   name: 'hall',
      //   component: () => import('@/views/hall')
      // },
      // {
      //   path: '/purchase',
      //   name: 'purchase',
      //   component: () => import('@/views/purchase')
      // },
      // {
      //   path: '/fixDetail',
      //   name: 'fixDetail',
      //   component: () => import('@/views/purchase/fixDetail')
      // },
      // {
      //   path: '/freeDetail',
      //   name: 'freeDetail',
      //   component: () => import('@/views/purchase/freeDetail')
      // },
      // {
      //   path: '/customizeDetail',
      //   name: 'customizeDetail',
      //   component: () => import('@/views/purchase/customizeDetail')
      // },
      // {
      //   path: '/contact',
      //   name: 'contact',
      //   component: () => import('@/views/contact')
      // },
    ]
  },
  {
    path: '/passport',
    name: 'passport',
    component: () => import('@/views/passport')
  },
  {
    path: '/tt',
    name: 'tt',
    component: () => import('@/views/tt')
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
    const query = {...to.query}
    let isChange = false;
    if(from.query.invite && !to.query.invite) {
        query.invite = from.query.invite;
        isChange = true;
    }
    if(isChange) {
        router.replace({
            name: to.name,
            path: to.path,
            query: query,
            replace: true
        })
        return
    }
    return next();
});

export default router;